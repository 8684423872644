import React, { Component } from 'react'
import { Table } from 'reactstrap';
import { CSVReader } from 'react-papaparse'
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helmet } from "react-helmet";
import { Form, Row, Col, Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import '../style/style.css'
import cincin from '../assets/img/cincin.svg'
import gambar8 from '../assets/img/gambar8.png'
import music from '../assets/music/music.aac'
import logoig from '../assets/img/logoig.svg'
import gold5 from '../assets/img/gold5.png'
import groom from '../assets/img/groom.png'
import burung from '../assets/img/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import logo from '../assets/img/logo.ico'
import AOS from 'aos';
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()

    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true
    }
  }

  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    let acara = [[
      'Akad Nikah',
      '10 oktober 2020',
      'Masjid Roudotul Muchlisin',
      '08:00 - 10:00 WIB'
    ],
    [
      'Resepsi',
      '10 oktober 2020',
      'Ijen View Hotel and Lounge',
      '17:00 - 21:00 WIB'
    ]
    ]
    this.setState({ acara: acara })
    var countDownDate = new Date("10/10/2020").getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })


    }, 1000);

  }
  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('sectiongold52');
      elmnt.scrollIntoView();
    }, 1000)
  }
  googleCalendar = () => {
    window.open(`https://calendar.google.com/calendar/r/eventedit?
text=Event%20Title
&details=Pernikahan%20Willy%20dan%20Jessica
&dates=20201010T100000/20201010T113000
&ctz=Indonesia%2FEast_Java&title=Pernikahan%20Willy%20dan%20Jessica
    `, '_blank')
  }
  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  render() {
    let { acara, days, hours, minutes, seconds, hide } = this.state
    return (
      <>
        <Helmet>
          <title>Undanganku - Gold 5</title>
          <meta name="title" content="Preview Undanganku Gold5" />
          <meta name="description" content="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern" />
          <link rel="icon" href={logo}></link>
        </Helmet>
        <div id='gold5'>
          {
            this.useQuery().get('x') == "x" ? (<Float />) : false
          }
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw'
          }}>
            <div className="jumbotrongold5 jumbotron-fluid mb-0 w-100" id='sectiongold51' style={{
              overflow: 'hidden', maxWidth: '100vw'
            }}>
              <div className="container" >
                <div className='position' data-aos="flip-left" data-aos-duration="1000" >
                  <Item><h1>The Wedding of</h1>
                  </Item>
                </div>
                <div
                  className='mt-3 nama'
                  data-aos="zoom-in" data-aos-duration="1000"
                >
                  <Item>
                    Danny
                      </Item>
                  <Item>
                    &
            </Item>
                  <Item>
                    Jessica
            </Item>
                </div>
                <div className='tombol'>
                  <Item>
                    <button
                      onClick={() => {
                        this.play()
                      }}
                      style={{
                      }}
                      data-aos="fade-up" data-aos-duration="1000"
                    >Open Invitation</button>
                  </Item>
                </div>
              </div>
            </div>
            <div className={hide ? 'd-none' : 'd-block'}>


              <Container id='sectiongold52' className='w-100' ref={this.myRef}>
                <div className='main'>

                  {/* <Item>
                      <h1>
                        Get Married
                    </h1>
                    </Item> */}
                  <Item>
                    <Col xs={12} md={8}>
                      <img src={gold5} className='img-fluid w-100' data-aos="flip-left" />

                    </Col>
                  </Item>
                </div>
              </Container>
              <Container id='sectiongold53'>
                <Item>
                  <iframe className='col-12 col-md-8 p-3 video' data-aos="fade-up" data-aos-duration="1000" style={{ minHeight: '40vh' }} src="https://www.youtube.com/embed/gaKiJE2C8Tk"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; 
            picture-in-picture"
                    allowfullscreen></iframe>
                </Item>
              </Container>
              <Container id='sectiongold54'>
                <div className='pt-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img src={cincin} className='img-fluid w-100' data-aos="fade-left" data-aos-duration="1000" />
                    </Col>
                  </Item>
                  <Item>
                    <p className='text-center col-md-8 quotes pt-3' data-aos="fade-left"
                      data-aos-duration="1000">
                      “And of His signs is that He created for you from yourselves mates that youmay
                      find tranquility in them; and He placed between you affection and mercy.Indeed
                  in that are signs for a people who give thought.” <br /><br />(Ar-Rum: 21)
            </p>
                  </Item>
                </div>
              </Container>
              <Container id='sectiongold55'>
                <Item>
                  <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                    <div className='kotak mr-lg-2'>
                      <Item>
                        <h1>
                          The Groom
                </h1>
                      </Item>
                      <Item>
                        <Col xs={6}>
                          <img src={groom} className='img-fluid w-100' />
                        </Col>
                      </Item>
                      <Item>
                        <h1>
                          Danny Ipsum
            </h1>
                      </Item>
                      <Item>
                        <p className='text-center'>
                          The Son of<br />
                Mr. Lorem Ipsum and Mrs. Dolor Sit
            </p>
                      </Item>
                      <Item>
                        <Col xs={2}>
                          <img src={logoig} className='img-fluid w-100' />
                        </Col>
                      </Item>
                    </div>
                  </div>
                  <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                    <div className='kotak mr-lg-2'>
                      <Item>
                        <h1>
                          The Bride
            </h1>
                      </Item>
                      <Item>
                        <Col xs={6}>
                          <img src={groom} className='img-fluid w-100' />
                        </Col>
                      </Item>
                      <Item>
                        <h1>
                          Jessica Ipsum
            </h1>
                      </Item>
                      <Item>
                        <p className='text-center'>
                          The Son of<br />
                Mr. Lorem Ipsum and Mrs. Dolor Sit
            </p>
                      </Item>
                      <Item>
                        <Col xs={2}>
                          <img src={logoig} className='img-fluid w-100' />
                        </Col>
                      </Item>
                    </div>
                  </div>
                </Item>
              </Container>
              <Container id='sectiongold56'>
                <div className='pt-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <h1 data-aos="fade-right" data-aos-duration="1000">
                      Our Love History
              </h1>
                  </Item>
                  {
                    [['2015', `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et lacinia enim, 
              elementum egestas diam. Mauris at facilisis risus. Donec.`],
                    ['2016', `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et lacinia enim, 
              elementum egestas diam. Mauris at facilisis risus. Donec.`],
                    ['2020', `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque et lacinia enim, 
              elementum egestas diam. Mauris at facilisis risus. Donec.`],
                    ].map((val, i) => {
                      return (
                        <div data-aos={`fade-${i % 2 == 0 ? 'right' : 'left'}`} data-aos-duration="2000">
                          <Item>
                            <div className='kotak col-10'>
                              <Item>
                                <h2>
                                  {val[0]}
                                </h2>
                              </Item>
                              <Item>
                                <p className='text-center p-2'>
                                  {val[1]}
                                </p>
                              </Item>
                            </div>

                          </Item>
                          {i != 2 ?
                            (
                              <Item>
                                <div>
                                  |
                    </div>
                              </Item>
                            ) : false}
                        </div>
                      )
                    })
                  }

                </div>
              </Container>
              <Container id='sectiongold57'>
                <div className='pt-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  {
                    acara.map((val, i) => {
                      return (
                        <div data-aos={`fade-${i % 2 == 0 ? 'left' : 'right'}`} data-aos-duration="1000">
                          <Item>
                            <h1>
                              {val[0]}
                            </h1>
                          </Item>
                          <Item>
                            <p className='bold italic'>
                              {val[1]}
                            </p>
                          </Item>
                          <Item>
                            <p className='italic'>
                              {val[2]}
                            </p>
                          </Item>
                          <Item>
                            <p className='mt-2'>
                              {val[3]}
                            </p>
                          </Item>
                        </div>
                      )
                    })
                  }
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000" className='col-6 col-lg-4 mt-3 button text-center'>
                      Get Direction
                </div>
                  </Item>
                  <Item>
                    <div data-aos="zoom-in" data-aos-duration="1000" className='col-6 col-lg-4 mt-3 button text-center smaller' onClick={() => {
                      this.googleCalendar()
                    }}>
                      Add to Google Calendar
                </div>
                  </Item>
                  <Item>
                    <div className='col-8 pt-3'>
                      <p data-aos="zoom-in" data-aos-duration="1000" className='italic red text-center'> Diberitahukan kepada setiap tamu undangan
                      untuk mengikuti protocol kesehatan
dalam menghadiri acara Resepsi</p>
                    </div>
                  </Item>
                  <Item>
                    <div data-aos="fade-left" data-aos-duration="1000" className='col-10 col-lg-8 kotak'>
                      <Item>
                        <div className='item'>
                          <Item>
                            <div>
                              {days}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Days
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {hours}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Hours
                      </span>
                          </Item>
                        </div>
                        <div className='dot'>:</div>
                        <div className='item'>
                          <Item>
                            <div >
                              {minutes}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Mins
                      </span>
                          </Item>
                        </div>
                        <div className='dot' >:</div>
                        <div className='item'>
                          <Item>
                            <div>
                              {seconds}
                            </div>
                          </Item>
                          <Item>
                            <span>
                              Secs
                      </span>
                          </Item>
                        </div>
                      </Item>

                    </div>
                  </Item>
                </div></Container>
              <Container className='mt-3' data-aos="fade-right" data-aos-duration="1000">
                <Slider />
              </Container>
              <Container id='sectiongold58' >

                <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                  <Item>
                    <Col xs={4} lg={2}>
                      <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                      <Item>
                        <h1>
                          Send Your Wishes
                    </h1>
                      </Item>
                      <Item>
                        <form className="col-12 w-100 ">
                          <input type='text' className="col-12 w-100 text-center" placeholder="Nama" />
                          <input type='text' className="col-12 w-100 text-center" placeholder="Alamat" />
                          <input type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" />
                          <Item>
                            <input id="option" type="radio" name="field" value="option" defaultChecked />
                            <label for="option"><span><span></span></span>Hadir</label>
                            <input id="option" type="radio" name="field" value="option" />
                            <label for="option"><span><span></span></span>Tidak Hadir</label>
                          </Item>
                          <Item>
                            <div className='col-6 button btn p-0'> Kirim </div>
                          </Item>
                        </form>
                      </Item>
                    </div>
                  </Item>
                </div>
              </Container>
              <Foot ig={logoig} dark />
            </div>
          </div>
        </div>
      </>


    )
  }
}